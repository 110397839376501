@import 'variables';
@import 'colors';

#app {
  display: flex;
  flex-direction: column;
  min-height: 0px;
}

.line-height-normal {
  line-height: normal;
}

.line-height-lg {
  line-height: 1.27;
}

// This class is useful on elements that are children of flex displayed parents and that you want to
// grow to fit the view. Helps fix scroll bar issues.
.min-height-0 {
  min-height: 0;
}

// This class is useful on elements that are children of flex displayed parents and that you want to
// shrink beyond content size.
.min-width-0 {
  min-width: 0;
}

// This class is useful to force certain elements to remain as LTR when in RTL mode
/* rtl:ignore */
[dir='rtl'] {
  .ltrInRtl {
    direction: ltr;
    unicode-bidi: embed;
  }

  input.ltrInRtl {
    text-align: right;
  }
}

// These two anchor definitions are *specifically* for our in html reports
// since we don't actually flip the reports around, we need to reverse the nice default flipping
// the browser do for us
.text-anchor-start {
  text-anchor: start !important;

  html[dir='rtl'] & {
    text-anchor: end !important;
  }
}

.text-anchor-end {
  text-anchor: end !important;

  html[dir='rtl'] & {
    text-anchor: start !important;
  }
}

.text-anchor-middle {
  text-anchor: middle !important;
}

.text-break {
  word-break: break-all !important;
  word-break: break-word !important;
  -ms-word-break: break-all;
}

// all of the following commented sizes are based on $font-size-base being 1rem === 16px
.text-sm {
  font-size: $font-size-sm; // 12px
}

.text-md {
  font-size: ($font-size-md); // 14px
}

.text-normal {
  font-size: $font-size-base; // 16px
}

.text-lg {
  font-size: $font-size-lg; // 18px
}

.text-xl {
  font-size: ($font-size-base * 1.25); // 20px
}

.text-xxl {
  font-size: ($font-size-base * 1.375); // 22px
}

.text-xxxl {
  font-size: ($font-size-base * 1.875); // 30px
}

// Here we define rtl animations for bootstraps progress bar as postcss-rtl interprets it wrong
/* rtl:begin:ignore */
@-webkit-keyframes progress-bar-stripes-rtl {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1rem 0;
  }
}
@keyframes progress-bar-stripes-rtl {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1rem 0;
  }
}
/* rtl:end:ignore */
