/*
 * README!!

 * Our own colors is in our _colors.scss file, so make sure that you aren't unexpectedly overwriting
 * colors there. Both this file and the _colors.scss file is imported before bootstrap in our custom.scss file
 */

// Color system
// Color system - Bootstrap
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-600: #d0d5d8;
$gray-900: #212529;
$black: #333333;

$blue: #346b93;

$danger: #d41920;
$dark: #5c676e;
$info: #e0f2ff;
$light: #d0d5d8;
$primary: #0098ff;
$primary2: $blue; // Newyu
$secondary: #f2f2f3;
$secondary: #f4f7fa;
$steel: #868b8f;
$success: #18a93b;
$success2: #0c918b;

$theme-colors: (
  'black': $black,
  'dark': $dark,
  'primary2': $primary2,
  'steel': $steel,
  'success2': $success2,
  'white': $white,
);

$yiq-text-dark: rgba(0, 0, 0, 0.75);

// Options
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-validation-icons: false;

// Spacing
$spacer: 1rem;

// Body
$body-bg: $white;

// Components
$line-height-lg: 2;
$line-height-md: 1.45;
$line-height-sm: 1.2;

$border-width: 1px;
$border-color: rgba($black, 0.12);

$border-radius: 0.3rem;
$border-radius-lg: 0.5rem;
$border-radius-md: 0.27rem;
$border-radius-sm: 0.25rem;

$component-active-color: $white;

$caret-width: 0.3em;

$transition-fade: opacity 0.2s linear;

// Fonts
$font-family-sans-serif: Source Sans Pro, 'sans-serif';

$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.125;
$font-size-md: $font-size-base * 0.875;
$font-size-sm: $font-size-base * 0.75;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;

$small-font-size: $font-size-sm;

$headings-line-height: 1.2;

$display2-size: 5rem;
$display3-size: 4rem;
$display4-size: 3rem;

// Buttons + Forms
$input-btn-focus-width: 0.1rem;

$input-btn-padding-y-md: 0.25rem;
$input-btn-padding-x-md: 0.5rem;
$input-btn-font-size-md: $font-size-md;
$input-btn-line-height-md: $line-height-md;

// Buttons
$btn-box-shadow: none;
$btn-focus-width: $input-btn-focus-width;
$btn-active-box-shadow: none;

$btn-padding-y-md: $input-btn-padding-y-md;
$btn-padding-x-md: $input-btn-padding-x-md;
$btn-font-size-md: $input-btn-font-size-md;
$btn-line-height-md: $input-btn-line-height-md;
$btn-border-radius-md: $border-radius-md;

// Forms
$input-disabled-bg: $gray-100;

$input-border-width: 0px;
$input-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.34);

$custom-control-indicator-border-width: 1px;

// $custom-checkbox-indicator-icon-checked: url('../img/check-complete.svg');

$custom-select-disabled-color: $dark;
$custom-select-bg-size: 10px 5px;
// $custom-select-indicator: url('../img/arrow-down-blue.svg');
// $custom-select-background: $custom-select-indicator no-repeat;

//Dropdowns
$dropdown-link-hover-color: darken($gray-900, 5%);
$dropdown-link-hover-bg: $info;

// Cards
$card-cap-bg: $white;

// Tooltips
$tooltip-font-size: 0.875rem;
$tooltip-max-width: 200px;
$tooltip-bg: #868b8f;
$tooltip-opacity: 0.95;
$tooltip-padding-y: 0.4rem;
$tooltip-margin: 0.1rem;

$tooltip-arrow-color: #868b8f;

// Badges
$badge-font-size: 100%;
$badge-padding-x: 0.5em;
$badge-padding-y: 0.25em;

$badge-pill-padding-x: 1em;

// Modals
$modal-content-box-shadow-xs: 0 1rem 1rem rgba($black, 10);

$modal-lg: 900px;
$modal-md: 600px;

// Progress bars
$progress-bg: $gray-200;
